import React from "react";
import { graphql } from "gatsby";

import Navigation from "../components/navigation";

export default ({ data }) => {
    const post = data.markdownRemark;
    return (
        <Navigation>
            <div id="blogPost">
                <h1
                    id="main-title"
                    style={{ margin: "0rem 1rem", textAlign: "center" }}>
                    {post.frontmatter.title}
                </h1>
                <div
                    style={{ margin: "0rem 1rem" }}
                    dangerouslySetInnerHTML={{ __html: post.html }}
                />
            </div>
        </Navigation>
    );
};

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
        }
    }
`;
